@import "rsuite/dist/rsuite.css";

// Color Variables //
:root {
  --primary-color: #1c2536;
  --white: #ffffff;
  --primary-text-color: #232323;
  --secondary-text-color: #f0f0f0;
  --textgrey: #a5a5a5;
  --light-green: #ebf5d9;
  --green: #7cb900;
  --red: #ee5e5e;
  --body-background: #f0f0f0;
  --light-grey: #f0f0f0;
  --primary-text-color: #232323;
  --white: #ffffff;
  --secondary-text-color: #f0f0f0;
  --body-bg: #f4f4f4;
  --primary-btn-color: #293750;
  --primary-button-hover: #37486a;
  --primary-btn-hover: #3e4b61;
  --secondary-btn-hover: #e0e0e0;
  --scrollbar-thumb: #d9d9d9;
  --border: #dddddd;
  --scrollbar-thumb: #d9d9d9;
  --border: #dddddd;
  --pri-btn-color: #43acef;
  --primary-dark: #2090b7;
  --secondary-dark: #848484;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Regular.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  border-radius: 6px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  background-color: transparent;
  border-radius: 6;
}

::-webkit-scrollbar-thumb {
  border-radius: 6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-thumb);
}

::selection {
  background-color: #034ae2 !important;
  /* Yellow background */
  color: #ffffff !important;
  /* Black text */
}

/* For Firefox specifically */
::-moz-selection {
  background-color: #034ae2 !important;
  /* Yellow background */
  color: #ffffff !important;
  /* Black text */
}

body {
  margin: 0;
  background-color: var(--body-background) !important;
}

* {
  font-family: "Mona-Sans" !important;
  color: var(--primary-color);
}

html {
  font-family: "Mona-Sans" !important;
}

h1 {
  font-size: 26px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

p {
  color: var(--primary-text-color) !important;
}

img {
  max-width: 100%;
}

video {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

// Navbar Styles //
.main-content {
  margin-left: 260px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
}

.full-width {
  // margin-left: 0px;
  margin-left: 80px;
}

.navbar {
  position: sticky !important;
  z-index: 10 !important;
  background-color: var(--white) !important;

  .small-logo {
    display: none;
  }

  &.sidebar-closed {
    div {
      .navbar-left {
        gap: 0;
        min-width: 80px;

        .small-logo {
          display: block;
          width: 36.5px;
          position: relative;
          left: 10px;
        }

        .large-logo {
          display: none;
        }

        .toggle-btn {
          background-color: var(--primary-color);
          position: relative;
          left: 25px;

          .open-menu {
            display: none;
          }

          .close-menu {
            display: block;
          }
        }
      }

      .navbar-right {
        width: calc(100% - 80px);
      }
    }
  }

  .avatar-ui {
    border-radius: 10px;
  }

  div {
    padding: 0;

    .navbar-left {
      background-color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color) !important;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 16px;
      min-width: 260px;
      padding: 14px 8px;
      box-sizing: border-box;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;

      img {
        width: 144px;
        position: relative;
        left: 10px;
      }

      svg {
        fill: var(--white);
      }

      .toggle-btn {
        .close-menu {
          display: none;
        }
      }
    }

    .navbar-right {
      // border: 2px solid red;
      margin: 0 24px;
      width: calc(100% - 305px);
      // width: calc(100% - 260px);
      display: flex;
      align-items: center;
      // justify-content: flex-end;

      justify-content: space-between;

      .user-info {
        display: flex;
        align-items: center;

        gap: 8px;
        font-size: 16px;
        font-weight: 500;

        .MuiChip-root {
          background-color: var(--pri-btn-color);
          text-transform: capitalize;
          color: var(--white) !important;
          border-radius: 15px !important;
          padding: 4px 0px;
          white-space: nowrap;
          font-weight: 500;
        }

        button {
          text-transform: initial !important;
          color: var(--primary-color);
          font-size: 16px;
          font-weight: 500;
          min-width: 95px;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.sidebar-wrapper {
  position: relative;
  z-index: 1;

  &.sidebar-menu-closed {
    .MuiDrawer-paper {
      transform: none !important;
      visibility: unset !important;

      .list-wrap {
        width: 80px;

        .menu-text-wrap {
          height: 24px;

          span {
            display: none;
          }
        }

        // .icon-wrap{
        //   margin-left: 3px;
        // }
      }
    }
  }

  .MuiDrawer-paper {
    transition: all 0.5s !important;
    -webkit-transition: all 0.25s !important;
    transform: 0 !important;
    background-color: var(--primary-color) !important;
    // -webkit-transform: 0 !important;
    // -moz-transform: 0 !important;
    // -ms-transform: 0 !important;
    // -o-transform: 0 !important;
  }

  .list-wrap {
    height: 100vh;
    background-color: var(--primary-color);
    padding-top: 80px !important;
    padding-bottom: 16px;
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: all 0.5s;
    -webkit-transition: all 0.25s;

    ul.sidebar {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: var(--primary-color) !important;

      li {
        padding: 0 8px;

        .icon-wrap {
          min-width: 48px !important;

          svg {
            fill: var(--textgrey);
            font-size: 25px;
          }
        }

        span {
          color: var(--textgrey);
        }

        &:hover {
          svg {
            fill: var(--white);
          }

          span {
            color: var(--white);
          }
        }
      }
    }

    li.activemenu {
      .icon-wrap {
        svg {}
      }

      span {
        color: var(--white) !important;
      }
    }
  }
}

.content-header {
  // margin: 24px 24px 0px 24px;
  margin: 24px 24px 0px 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h2 {
    margin: 0;
  }
}

.content-header-title {
  // margin: 24px 24px 0px 24px;
  margin: 24px 24px 0px 24px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;

  h2 {
    margin: 0;
    font-size: 28px !important;
    font-weight: 600;
  }
}

.content-layout {
  border-radius: 5px;
  background-color: var(--white);
  line-height: 1rem !important;
  margin: 24px;

  h3 {
    margin-top: 0px;
  }
}

.main-content {
  margin-left: 260px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
}

.btn {
  border-radius: 8px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 8px 16px !important;
  line-height: 1.5rem !important;
}

.primary-btn {
  background-color: var(--pri-btn-color) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--primary-dark), 0 5px 15px !important;
  height: 35px !important;

  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }

  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }

  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.primary-btn {
  p {
    margin: 0;
    line-height: 21px;
    vertical-align: middle;
    color: var(--white) !important;
  }
}

.secondary-btn {
  background-color: var(--textgrey) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--secondary-dark), 0 5px 15px !important;
  height: 35px !important;

  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }

  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }

  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.modal-wrapper {
  padding: 28px;
  max-width: 600px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    h3 {
      margin: 0;
    }

    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .footer {
    margin-top: 48px;

    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

.modal-wrapper-change {
  padding: 34px;
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin: 24px 32px 32px 32px;

  @media (max-width: 767px) {
    padding: 16px;
  }

  @media only screen and (min-device-width: 480px) and (max-device-width: 740px) and (orientation: landscape) {
    position: relative;
    margin-top: 56px;
  }

  @media (max-width: 480px) {
    width: 80%;
    margin: 24px auto !important;
  }

  .title {
    h3 {
      margin: 0;
    }

    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }

  .footer {
    margin-top: 48px;

    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

.warning-popup {
  max-width: 500px !important;

  .small-text {
    font-weight: 500 !important;
  }
}

.login-main-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  color: var(--white);

  // gap: 30px;
  .logo-wrap {
    // text-align: center
    background: radial-gradient(50% 50% at 50% 50%,
        rgb(18, 38, 71) 0%,
        rgb(9, 14, 35) 100%);

    h2 {
      margin: 12px 0 0 0;
      color: var(--white);
    }
  }

  .login-form-wrap {
    // background-color: var(--white) !important;
    border-radius: 12px;
    position: relative;
    z-index: 2;
    max-width: 600px;
    width: 100%;

    .title {
      text-align: center;
      margin-bottom: 8px;

      h3 {
        margin: 0;
        font-size: 30px;
        font-weight: 600;
      }

      p {
        margin: 12px 0 0 0 !important;
      }
    }

    button.auth-btn {
      width: 100%;
      // margin-top: 16px;
      line-height: 1.2rem;
      font-size: 14px !important;
      border-radius: 5px !important;
      -webkit-border-radius: 5px !important;
      -moz-border-radius: 5px !important;
      -ms-border-radius: 5px !important;
      -o-border-radius: 5px !important;
    }

    .forgot-pw {
      display: flex;
      justify-content: space-between;
      margin-top: 10px !important;

      .link-btn {
        padding: 0 !important;
      }
    }
  }
}

.login-img-wrap {
  background: radial-gradient(50% 50% at 50% 50%,
      rgb(18, 38, 71) 0%,
      rgb(9, 14, 35) 100%);
  height: 100%;
}

.label {
  color: var(--primary-text-color) !important;
  font-size: 16px !important;
  display: flex;
  font-weight: 700 !important;
}

.label span.asterisk {
  color: var(--red) !important;
  font-size: 18px;
  line-height: 15px;
}

.value {
  color: var(--primary-text-color) !important;
  margin: 0;
  font-size: 16px !important;
  // text-justify: ;
}

.forgot-pw {
  text-transform: capitalize;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.login-or {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.singup-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.link-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  // text-transform:
}

.signup-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px !important;
}

.error-message {
  color: var(--red) !important;
  margin-left: -12px;
}

.home-error-message {
  color: var(--white) !important;
  margin-left: -12px;
}

.custom-paper-class {
  transform: none !important;
  visibility: unset !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
}

.content-wrapper {
  // margin: 24px 32px 32px 32px;
  margin: 24px;
  // border: 1px solid grey;
  // background-color: rgba(220, 220, 220, 0.503);
  border-radius: 8px;

  // padding: 10px;
  .mb-24 {
    margin-bottom: 24px !important;
  }

  .divider {
    border-color: var(--body-background);
  }

  .card-wrapper {
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px;

    &.add-scroll-space {
      padding: 24px 14px 24px 24px;

      .over-flow-auto {
        overflow: auto;
        padding-right: 10px;
      }
    }

    &.add-bottom-scroll-space {
      padding: 24px 24px 14px 24px;

      .over-flow-bottom-auto {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
      }
    }
  }
}

.auth-image {
  height: 250px !important;
  width: 250px !important;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 22px !important;
    margin-left: -20px !important;
  }
}

// --------------- header view acc ---------------------
.account-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    min-width: 230px;

    // overflow: auto;
    .user-details {
      .user-img {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          object-fit: contain;
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
          background-color: rgb(204, 204, 204);
          border: 0px !important;
        }

        .innerIcon {
          padding: 8px !important;
        }
      }

      .user-name {
        //   border: 2px solid var(--green);
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px;
        text-align: center;
      }

      .user-position {
        text-align: center;
        display: block;
        font-weight: 400 !important;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .divider-border {
      background: var(--light-green);
      margin: 15px 0px;
    }

    .notification-menu {
      padding: 5px;

      &:hover {
        border-radius: 15px;
      }
    }

    .notification-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;

      gap: 10px;
      padding: 0 5px;

      // border: 2px solid red;
      .u-img {
        width: 25px !important;
        height: 25px !important;
        // background-color: rgb(204, 204, 204);
        padding: 2px;
        border-radius: 100px;
        // border: 2px solid var(--green);
        margin-bottom: 14px;

        // margin-left: 5px;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }

      .org-text {
        h5 {
          font-weight: 500 !important;
          font-size: 15px !important;
          //   line-height: 15px;
          white-space: nowrap;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }

        .notification-subject {
          // font-weight: 400 !important;
          font-size: 14px !important;
          // color: black !important;
        }
      }
    }

    .organization-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;

      .org-img {
        width: 30px !important;
        height: 45px !important;
        background-color: black;
        padding: 2px;
        // border-radius: 100px;
        border: 2px solid green;
        margin-bottom: 5px;
        // img {
        //   width: 100%;
        //   border-radius: 100%;
        // }
      }

      .org-text {
        h5 {
          font-weight: 500 !important;
          font-size: 15px !important;
          //   line-height: 15px;
          white-space: nowrap;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }
      }
    }

    .links {
      gap: 15px;
      display: flex;
      flex-direction: column;

      .menu-link {
        // gap: 14px;
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        min-height: auto;

        &:hover,
        &:focus {
          background-color: transparent;
        }

        .link-icon {
          margin-left: 6px;
          min-width: 22px;
          opacity: 0.6 !important;
          font-size: 18px !important;
        }

        .link-text {
          margin-left: 10px;
        }
      }
    }
  }
}

.user-info-menu {
  .MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 1px rgba(0, 0, 0, 0.12) !important;
  }
}

.flex {
  display: flex;
}

.kep-login-facebook {
  background-color: transparent !important;
  padding: 0px !important;
  line-height: 1.2rem !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  border: none !important;
}

.head-row {
  .MuiTableCell-root {
    font-size: 15px !important;
    color: var(--primary-color) !important;
    font-weight: 600 !important;
    font-size: 16px !important;
  }
}

.listing-action-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border: none !important;
  border-radius: 8px !important;
  background-color: var(--light-grey) !important;

  span {
    margin: 0;
  }

  .icon-font {
    font-size: 22px;
  }

  &:hover {
    background-color: var(--white);
    cursor: pointer;
    /* Change cursor on hover */
  }
}

.listing-action-btn-user {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 35px !important;
  height: 35px;
  padding: 0 !important;
  border: none !important;
  border-radius: 8px !important;
  background-color: var(--light-grey) !important;

  span {
    margin: 0;
  }

  .icon-font {
    font-size: 20px;
  }

  &:hover {
    background-color: var(--white);
    cursor: pointer;
    /* Change cursor on hover */
  }
}

.action-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border: none !important;
  border-radius: 10px !important;

  // background-color: var(--light-grey) !important;
  span {
    margin: 0;
  }

  .icon-font {
    font-size: 20px;
  }
}

.MuiOutlinedInput-root.Mui-focused {
  border-color: transparent !important; // Change the border color when focused
}

/* Apply styles for focus to the specified input types */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  /* Your focus styles here */
  outline: none !important;
  /* Remove default focus outline */
  border: none !important;
  /* Example border color */
  box-shadow: none !important;
  /* Add other focus styles as needed */
}

.modal-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.modal-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: center;
}

.not-found {
  height: 100vh;

  .page-inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 20px;
  }

  .not-found-title {
    font-size: 28px;
    font-weight: 600;
  }
}

.facebook-icon {
  height: 20px !important;
}

.signup-text {

  // .text-label {
  /* Target the text field and set the font size */
  .MuiOutlinedInput-root .MuiInputBase-input {
    // font-size: 14px; /* Adjust the font size as needed */
    // padding: 5px 14px;
  }

  // }
}

.text-label {
  font-size: 14px !important;
}

.MuiFormHelperText-root {
  line-height: 0.7rem !important;
  font-size: 14px !important;
  margin-top: 5px !important;
}

.input-field {
  margin-top: 8px !important;
}

// .ck-editor-wrapper {
.ck-toolbar {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ck-editor__editable {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  min-height: 200px !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  color: var(--white);
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background: var(--primary-text-color);
  border-radius: 5px;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: var(--primary-text-color);
  border-radius: 5px;
  color: var(--white);
}

// }

.facebook {
  background-color: rgb(66, 133, 244);
  color: rgb(255, 255, 255);
  height: 50px;
  width: 240px;
  border: none;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
    box-shadow 0.218s ease 0s;
  font-family: Roboto, arial, sans-serif;
  cursor: pointer;
  user-select: none;
}

.google-btn {
  span {
    color: var(--white) !important;
  }
}

.btn-group-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.chat-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-right: 24px;
  position: fixed;
  bottom: 0;
  max-width: 1230px;
  width: 100%;
  z-index: 0;
  left: 0;
  // margin-top: 423px;
  margin-left: 295px;
  margin-bottom: 33px;
  // margin-right: 100px;
}

.dashboard-left-content-layout {
  border-radius: 5px;
  background-color: var(--white);
  line-height: 1rem !important;
  // margin: 24px;
}

.view-more-text {
  color: #c0c0c0 !important;
}

.auth-btn {
  background-color: var(--primary-btn-color) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--primary-btn-hover), 0 5px 15px !important;
  height: 35px !important;

  &:hover {
    opacity: 0.95 !important;
    outline: 0 !important;
  }

  &:focus {
    opacity: 0.95 !important;
    outline: 0 !important;
  }

  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.auth-btn {
  p {
    margin: 0;
    line-height: 21px;
    vertical-align: middle;
    color: var(--white) !important;
  }
}

.view-more-btn {
  text-transform: capitalize !important;
  gap: 5px !important;
}

.edit-profile-view {

  // display: flex;
  // flex-wrap: wrap;
  // gap: 10px;
  .edit-profile-picture {

    // width: 16%;
    // @media (max-width: 1550px) {
    //     width: 20%;
    // }
    // @media (max-width: 992px) {
    //     width: 30%;
    // }
    // @media (max-width: 767px) {
    //     width: 100%;
    // }
    .image-wrap {
      display: inline-flex;
      width: 100%;
      position: relative;

      .image {
        background-color: var(--light-grey);
        border: 2px solid var(--primary-text-color);
        border-radius: 10px;
        max-height: 250px;
        max-width: 250px;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .image img {
        object-fit: cover;
        /* Make the image cover the entire circle */
        width: 100%;
        height: 100%;
      }

      button.upld-btn {
        position: absolute;
        right: 34px;
        top: 193px;
        height: 30px !important;
        min-width: 30px !important;
        background-color: var(--primary-color);
        border-radius: 50% !important;

        svg {
          fill: var(--body-bg);
          font-size: 16px;
        }
      }
    }

    .btn-wrap {
      width: 100%;
      margin-top: 16px;

      .outline-btn {
        margin-top: 16px;
      }
    }
  }

  .edit-profile-detail {
    width: 80%;

    @media (max-width: 1550px) {
      width: 78%;
    }

    @media (max-width: 992px) {
      width: 78%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .btn-wrap {
      .outline-btn {
        margin-top: 16px;
      }
    }
  }
}

.error-border .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.error,
.error-message {
  color: var(--red) !important;
  font-size: 14px !important;
  margin-top: 4px;
}

.MuiAvatar-img {
  object-fit: contain !important;
}

.content-wrapper-policy {
  margin: 24px 24px 0px 24px;
}

.policy-text {
  color: var(--white) !important;
  margin: 0;
  font-size: 16px !important;
}

.login-footer {
  bottom: 0px !important;
  position: fixed;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  .link {
    color: var(--textgrey) !important;
    font-weight: 600;
    font-size: 16px;
    // text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: var(--pri-btn-color) !important;
    }
  }
}

.footer-class {
  .small-content {
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.s-14 {
  span {
    font-size: 14px !important;
  }
}

.left-attempt-container {
  // border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  margin: 24px;
  background-color: rgba(220, 220, 220, 0.503);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: -15px;

  p {
    font-size: 15px;
    font-weight: 600;
    color: rgb(90, 90, 90) !important;
  }

  .content {
    width: max-content;
    text-align: right;
  }
}

.one-line-status {
  margin-right: 20px;
  width: 100%;
  overflow: hidden;
  position: relative;

  // border: 2px solid green;
  p {
    font-size: 15px;
    font-weight: 600;
    color: rgb(90, 90, 90) !important;
  }

  .content {
    width: max-content;
    text-align: right;
  }
}

.custom-progress {
  // border: 2px solid red;
  padding-left: 0 !important;

  .rs-progress-line-inner {
    height: 10px;
  }

  .rs-progress-line-bg {
    height: 10px;
    background: linear-gradient(90deg, #a8d3fd, #76baff, #1976d2);
    // background: linear-gradient(90deg, #7fff78,#34d82b, #016e11);
  }
}

.custom-progress-fav {
  .rs-progress-line {
    padding: 0;
  }

  .rs-progress-line-outer {
    width: 100%;
    // height: 7px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .rs-progress-line-inner {
    // background: none;

    top: 0;
    left: 0;
    height: 7px;
  }

  .rs-progress-line-bg {
    top: 0;
    left: 0;
    height: 7px;
    position: absolute;
    background: linear-gradient(90deg, #b4daff, #1976d2);
  }
}

.progress-container {
  position: relative;
  width: 100%;
}

.progress-filled {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  /* Prevent interactions with the filled area */
}

.tooltip-target {
  height: 100%;
  width: 100%;
  pointer-events: auto;
  /* Enable hover interaction for the tooltip */
}

.custom-alert {
  .MuiAlert-message {
    color: white !important;
  }

  .MuiSvgIcon-root {
    fill: white !important;

  }
}

.setting-card {

  // width: 150px;
  .section-card {
    height: 120px;
    background-color: #38b4dd;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 16px;
    /* Equivalent to `padding: 2` in MUI's spacing system */
    border-radius: 10px;
    /* Equivalent to `borderRadius: 1` in MUI's spacing system */
    box-sizing: border-box;

    border-left:4px solid #2195bcf1;
    border-bottom: 4px solid #1c81a2c9;

   
  cursor: pointer;

  &:hover{
    background-color: #35bae6ed;
  }
  }

  .section-label {
    p {
      color: white !important;
      font-weight: 600;
      margin-right: 15px;
      font-size: 18px;
    }
  }
}

.chat-drawer{
  .MuiPaper-root{
    width: 700px !important;
  }
}