.overview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #04315c, #021425, #04315c) !important;

  // background: linear-gradient(90deg, #032647, #031b32, #0b131c, ) !important;
  // opacity: 0.8;
  // background-color: #0a1736;
  // linear-gradient(90deg, #04315c, #031b32, #234469) !important
  /* Adjust to match your theme */
  p {
    color: #ffffff !important;
  }

  // color: #ffffff !important;
  min-height: 100vh;
  // border: 2px solid red;
}

.header {
  color: #ffffff !important;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  // border: 2px solid green;

  img {

    height: 90px;
    width: 120px;
  }

  .exam-title {
    font-size: 35px;
    font-weight: bolder;
    color: #78cbff !important;
  }
}

.content {
  // border: 2px solid red;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin: auto;
  margin-top: 70px;
  // flex-wrap: wrap;
  width: 80%;

  .title-content {
    font-weight: bold;
    font-size: 23px;
    color: #9bd8fd !important;
    padding-left: 10px;
  }
  .title-content::before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 14px;
    background: linear-gradient(45deg, #87c4fe, #106cc2);
    border-radius: 20%; /* Creates curly edges */
    transform: rotate(45deg); /* Turns square into a rhombus */
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.273); /* White shadow */
  }

  .sub-title-content {
    font-weight: bold;
    font-size: 19px;
    color: #56bdfd !important;
    // border-left: 4px solid #169deb;
    // border-left: 5px solid #169deb;
    // padding-left: 10px;
  }
  // max-width: 1200px;
  .text-content {
    max-width: 100%;
    // border: 2px solid green;
    text-align: left;
    margin-top: 10px;
    font-size: 17px;
    color: rgb(205, 205, 205) !important;
  } 
  .sub-info {
    padding: 0 40px;
  }
  .intro {
    display: flex;
    justify-content: space-between;
    gap: 60px;
margin-bottom: 50px;
    

    .exam-image-container {
      max-width: 27%;
      min-width: 27%;
      text-align: center;
    }

    .floating-image {
      width: 700px;
      animation: float 3s ease-in-out infinite;
    }
  }

  .essay-section {}

  .card{
    // border: 1px solid rgba(255, 255, 255, 0.184);
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.059);
  }
}




@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}