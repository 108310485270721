.homelayout {
  background-color: var(--white) !important;
  circle {
    color: var(--pri-btn-color) !important;
  }
  a {
    color: white !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
}

//CODE FOR ADJUSTING BOTH THE CAROUSEL IN SCREEN WITHOUT SCROLL
.carousel-btn {
  button {
    position: absolute !important;
    // z-index: 1 !important;
    // bottom: 40px !important;
  }
}

// HOME PAGE HEADER
.home-nav {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
  // background-color: var(--primary-color) !important;
}

.MuiDrawer-root .MuiDrawer-modal .MuiModal-root {
  display: block !important;
}
.scrolled-nav {
  background-color: var(--primary-color) !important;
}

.home-title {
  color: var(--white);
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 15px;
}

.drawer-title {
  padding: 20px;
}

.home-toolbar {
  padding-left: 65px !important;
  padding-right: 65px !important;
  display: flex;
  justify-content: space-between !important;
  .white-icon-button {
    display: none !important;
  }
  @media screen and (max-width: 947.98px) {
    .white-icon-button {
      display: flex !important;
    }
  }
}

.button-text {
  text-transform: capitalize !important;
}

.white-icon-button {
  svg {
    fill: var(--white) !important;
  }
}

.mobile-nav {
  background-color: var(--primary-color) !important;
  .home-title {
    padding: 0 20px !important;
  }
}

.home-white-font {
  span {
    color: var(--white) !important;
    font-size: 18px !important;
  }
}

.home-title {
  font-size: 28px !important;
  color: var(--pri-btn-color) !important;
  font-weight: 500 !important;

  // @media screen and (max-width: 599.98px) {
  //   display: none !important;
  // }
}

.home-tagline {
  font-size: 42px !important;
  color: var(--primary-color) !important;
  font-weight: 700 !important;
  line-height: 56px !important;
  @media screen and (max-width: 601.98px) {
    font-size: 28px !important;
    line-height: 32px !important;
  }
}

//ABOUT SECTION
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px;
  @media screen and (max-width: 601.98px) {
    padding: 25px;
  }
  @media screen and (max-width: 410.98px) {
    padding: 10px;
  }
  .about-content {
    font-size: 16px !important;
    color: var(--primary-color) !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  .about-points {
    font-size: 22px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    color: var(--pri-btn-color) !important;
  }

  .about-description {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--primary-color) !important;
    line-height: 24px !important;
  }
  .about-img {
    height: 100% !important;
  }
}

//SERVICE SECTION
.services-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-color: var(--pri-btn-color) !important;
  .service-img {
    height: 100%;
    width: 100%;
  }

  .service-width {
    max-width: 596px !important;
    margin: 0 32px;
  }
  .service-image {
    position: absolute;
    top: 7px;
    right: 0px;
  }
  .ser-content-layout {
    padding: 24px !important;
  }
}

.services-title {
  color: var(--white) !important;
  line-height: 28px !important;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.service-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: var(--white) !important;
}

.service-points {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: var(--white) !important;
  line-height: 24px !important;
}

//SUBSCRIPTION SECTION
.subscription-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  width: 100%;
  @media screen and (max-width: 601.98px) {
    padding: 25px;
  }
  @media screen and (max-width: 410.98px) {
    padding: 10px;
  }
  .home-tab {
    width: 100%;

    button {
      min-height: 38px !important;
      padding: 12px 36px !important;
      font-weight: 600 !important;
    }
    .MuiTabs-centered {
      justify-content: space-between !important;
      @media screen and (max-width: 460.98px) {
        flex-direction: column !important;
      }
    }
  }
}
.color-white {
  color: var(--white) !important;
}

.pl-0 {
  padding-left: 5px !important;
}

.center {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  flex-direction: column;
}

.MuiTabs-indicator {
  display: none !important;
}

.border-left {
  border-left: 1px solid var(--pri-btn-color) !important;
}

.price-rate {
  color: var(--pri-btn-color) !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

.price-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
}

.accordian-question {
  background-color: var(--light-grey) !important;
  // height: 48px !important;
}

.expanded-section {
  .accordian-question {
    background-color: #edf8ff !important;
  }
  background-color: #edf8ff !important;
  p {
    color: var(--pri-btn-color) !important;
  }
  .MuiAccordionSummary-expandIconWrapper {
    background-color: #edf8ff !important;
  }
}

.MuiAccordion-root {
  min-height: 56px !important;
  border-left: 3px solid var(--pri-btn-color) !important;

  &.Mui-expanded {
    margin: 0 0 16px 0 !important;
  }

  &:before {
    display: none !important;
  }

  box-shadow: unset;
  border-radius: 0;
}

.MuiAccordionSummary-expandIconWrapper {
  background-color: var(--light-grey) !important;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      color: var(--primary-text-color);
    }
  }
}

.accordion-title {
  padding: 12px 24px;
  min-height: auto !important;

  .action-btn {
    min-width: 30px !important;
    max-width: 30px;
    height: 30px;
    padding: 0 !important;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    margin: 0;
  }
}
.title-content {
  p {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
}

.blue-bg {
  background-color: var(--pri-btn-color) !important;
}

.max-width {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  @media screen and (max-width: 601.98px) {
    padding: 25px;
  }
  @media screen and (max-width: 410.98px) {
    padding: 10px;
  }
  p {
    margin-top: 0px !important;
  }
}

.home-textfield {
  .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    color: white !important;
  }
}
.white-placeholder input::placeholder {
  color: white !important;
}

.white-placeholder textarea::placeholder {
  color: white !important;
}

body {
  min-height: 100vh;
}
.slider {
  height: 60px;
  position: relative;
  width: 100%;
  background-color: var(--pri-btn-color) !important;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slider::before,
.slider::after {
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW FOR SLIDER CAROUSEL */

.slide-track {
  width: calc(150px * 20);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.slide-track2 {
  width: calc(150px * 15);
  display: flex;
  animation: scroll2 15s linear infinite;
  justify-content: center;
  .MuiChip-root {
    background-color: white !important;
    text-transform: capitalize;
    color: var(--pri-btn-color) !important;
    border-radius: 15px !important;
    padding: 4px 0px;
    white-space: nowrap;
    font-weight: 500;
  }
  .MuiChip-label {
    color: var(--pri-btn-color) !important;
    font-size: 14px !important;
  }
}

.slide {
  width: 150px;
  height: 60px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}
.slide:hover {
  transform: scale(0.8);
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 5));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(80px * 20);
  }

  .slide-track2 {
    width: calc(80px * 15);
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 10));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 5));
    }
  }
}

.home-btn {
  background-color: var(--white) !important;
  color: var(--pri-btn-color) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--primary-dark), 0 5px 15px !important;
  height: 35px !important;
  width: 100% !important;
  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.herobanner {
  width: 100%;
  height: 100%;
  min-height: 92vh;
  max-height: 92vh;
  @media screen and (max-width: 410.98px) {
    min-height: 80vh;
    max-height: 80vh;
  }
}

.tagline {
  font-weight: 700 !important;
  font-size: 58px !important;
  // line-height: 80px !important;
  color: var(--white) !important;
  @media screen and (max-width: 941.98px) {
    font-size: 52px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 704.98px) {
    font-size: 46px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 616.98px) {
    font-size: 38px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 531.98px) {
    font-size: 32px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 466.98px) {
    font-size: 30px !important;
    font-weight: 700 !important;
  }
  @media screen and (max-width: 410.98px) {
    font-size: 22px !important;
  }
  @media screen and (max-width: 358.98px) {
    font-size: 18px !important;
  }
}

.sizesmall {
  font-size: 48px !important;
  @media screen and (max-width: 718.98px) {
    font-size: 44px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 666.98px) {
    font-size: 34px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 535.98px) {
    font-size: 24px !important;
    font-weight: 700 !important;
    // line-height: 28px !important;
  }
  @media screen and (max-width: 410.98px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 358.98px) {
    font-size: 14px !important;
  }
}

.home-description {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: var(--white) !important;
  @media screen and (max-width: 920.98px) {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }
  @media screen and (max-width: 466.98px) {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }
  @media screen and (max-width: 389.98px) {
    font-size: 12px !important;
  }
}

.home-content {
  position: absolute;
  z-index: 1000 !important;
  top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 60px;
  text-align: center;
  @media screen and (max-width: 1021.98px) {
    top: 140px;
    padding: 0 45px;
  }
  @media screen and (max-width: 424.98px) {
    top: 100px;
    padding: 0 45px;
  }
}

.header-btn {
  background-color: var(--pri-btn-color) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--primary-dark) !important;
  height: 35px !important;
  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.home-footer {
  min-height: 92px;
  background-color: var(--primary-color) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  @media screen and (max-width: 701.98px) {
    padding: 20px;
    flex-direction: column;
    .footer-content {
      margin-top: 20px;
    }
  }
}

.footer-content {
  display: flex;
  gap: 20px;
}

.footer-link {
  cursor: pointer !important;
  &:hover {
    color: var(--pri-btn-color) !important;
    text-decoration: underline !important;
  }
}

.subscription-options {
  border: 1px solid #000; // Adjust the border color as needed
  border-radius: 5px;
  min-width: 461px;
  @media screen and (max-width: 601.98px) {
    min-width: 200px;
  }
}

.subscription-handler {
  width: 100% !important ;
  display: flex !important;
  justify-content: center !important;
}

.home-sub-card {
  border: 1px solid var(--pri-btn-color);
  border-radius: 5px;
  text-align: center;
  max-width: 345px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation-options {
  @media screen and (max-width: 947.98px) {
    display: none !important;
  }
}

.contact-us {
  a {
    color: white !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.home-sidemenu {
  ul {
    // background-color: var(--primary-color) !important;
    span {
      color: var(--white) !important;
      // background-color: var(--primary-color) !important;
    }
  }
}
