.chat {
  display: inline-block !important;
}

.ai-color {
  color: var(--white) !important;
}

.chat-msg {
  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.btn-loader {
  .MuiCircularProgress-colorPrimary {
    height: 24px !important;
    width: 24px !important;
    margin-top: 7px;
  }
  .MuiCircularProgress-svg {
    color: var(--white) !important;
  }
  .MuiCircularProgress-circle {
    color: var(--white) !important;
  }
}

/* Chatbot.css */
.chat-container {
  overflow-y: scroll;
  min-height: calc(100vh - 250px);
  max-height: calc(100vh - 250px);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  scroll-behavior: smooth;
}

.chat-container table {
  width: 100%;
}

.chat-container .ai {
  text-align: left;
  // background-color: rgb(0, 132, 255);
  color: white;
}

.chat-container .user {
  text-align: right;
  // background-color: rgb(224, 221, 221);
  color: black;
}

.chat-container td {
  padding: 8px;
  max-width: 600px;
  border-radius: 12px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.arrow-btn {
  .MuiButton-label {
    color: var(--white) !important;
  }
  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
    color: var(--white) !important;
  }
  svg,
  path {
    color: var(--white) !important;
  }
}

.chat-box {
  max-width: calc(100% - 10px) !important;
}

.chatbot-modal-wrapper {
  padding: 28px;
  max-width: 550px;
  width: 100%;
  // max-height: 600px;
  // height: 100%;
  border-radius: 5px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  .title {
    // h1 {
    //   font-size: 26px !important;
    // }
    // h2 {
    //   font-size: 22px !important;
    // }
    // h3 {
    //   font-size: 18px !important;
    // }
    // h4 {
    //   font-size: 16px !important;
    // }
    p {
      //   color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .footer {
    margin-top: 48px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
  .select__control--is-focused {
    border-color: var(--pri-btn-color) !important;
  }
}

.submit-arrow {
  color: var(--white) !important;
}

.custom-menu-item {
  display: list-item !important;
  height: 36px !important;
  padding-left: 16px !important;
  padding-top: 5px !important;
}

.modal-select .MuiOutlinedInput-input {
  padding: 6px 7px !important;
}
