.policy-content-header-title {
  // margin: 24px 24px 0px 24px;
  margin: 24px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    height: 120px;
  }
  h2 {
    margin: 20px 10px 0px 10px;
    font-size: 28px !important;
    font-weight: 700 !important;
  }
}

.policy-content-layout {
  border-radius: 5px;
  background-color: var(--primary-color);
  line-height: 1rem !important;
  margin: 24px 0px;
  h3 {
    margin-top: 0px;
  }
  .label {
    color: var(--white) !important;
    justify-content: center !important;
    margin: auto;
    display: flex;
    // align-items: center;
    flex-direction: column;
    // max-width: 800px;
    font-weight: 500 !important;
  }
  .MuiList-root,
  .MuiListItem-root {
    color: var(--white) !important;
  }
  .policy-item {
    color: var(--white) !important;
    margin-top: 3px;
  }

  .policy-item-nm {
    color: var(--white) !important;
  }
  .link-color-head {
    font-weight: bold;
    margin-right: 8px;
    color: var(--pri-btn-color) !important;
    // text-transform: uppercase;
    letter-spacing: 0.075rem;
  }
  .link-alpha-head {
    font-weight: 600;
    margin-right: 8px;
    color: var(--pri-btn-color) !important;
  }
  .link-redirect {
    font-weight: 600;
    color: var(--pri-btn-color) !important;
  }
}

.footer-wrapper {
  padding-top: 8px;
  // background-color: #787878 !important;
  // padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  z-index: 0;
  // max-width: 900px;
  .link {
    color: var(--textgrey) !important;
    font-weight: 600;
    font-size: 16px;
    // text-transform: uppercase;
    cursor: pointer;
    &:hover {
      color: var(--pri-btn-color) !important;
    }
  }
}

.link-color {
  color: var(--pri-btn-color) !important;
}

.policy-list-content {
  div {
    color: var(--white) !important;
  }
}
