.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.selector {
  margin-bottom: 20px;
}

// .selector button {
//   margin: 0 10px;
//   padding: 10px 20px;
//   cursor: pointer;
//   border: 1px solid #ccc;
//   background-color: #f0f0f0;
//   border-radius: 4px;
//   outline: none;
//   transition: background-color 0.3s;
// }

// .selector button:hover {
//   background-color: #e0e0e0;
// }

// .selector button.active {
//   background-color: #007bff;
//   color: white;
//   border-color: #007bff;
// }

.price-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.price-card {
  border: 1px solid #ccc;
  padding: 40px 20px;
  text-align: center;
  flex: 1 1 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  background-color: var(--white);
  border-radius: 10px;
}

.price-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.price-card h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.price-card p {
  margin: 5px 0;
}

.price-card .price {
  font-size: 1.2em;
  color: #333;
}

.price-card .price-id {
  font-size: 0.8em;
  color: #888;
}

.price-card .original-monthly-cost {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .price-cards {
    flex-direction: column;
    align-items: center;
  }

  .price-card {
    max-width: 90%;
  }
}

.content-header-subscription {
  // margin: 24px 24px 0px 24px;
  margin: 42px 24px 0px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  h2 {
    margin: 0;
  }
}

.content-header-title {
  // margin: 24px 24px 0px 24px;
  margin: 24px 24px 0px 24px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  h2 {
    margin: 0;
    font-size: 28px !important;
    font-weight: 600;
  }
}

.content-wrapper-subscription {
  margin: 42px 32px 0px 32px;
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .divider {
    border-color: var(--body-background);
  }
  .card-wrapper {
    //   background-color: var(--white);
    border-radius: 12px;
    padding: 24px;
    &.add-scroll-space {
      padding: 24px 14px 24px 24px;
      .over-flow-auto {
        overflow: auto;
        padding-right: 10px;
      }
    }
    &.add-bottom-scroll-space {
      padding: 24px 24px 14px 24px;
      .over-flow-bottom-auto {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
      }
    }
  }
}

.subscribe {
  .MuiChip-label {
    color: var(--white) !important;
    font-size: 14px !important;
  }
}

.unsubscribe {
  .MuiChip-label {
    color: var(--primary-text-color) !important;
    font-size: 14px !important;
  }
}
.selected {
  background-color: var(--primary-dark) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 #197392, 0 5px 15px !important;
  height: 35px !important;
  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}
.green-bg {
  background-color: var(--textgrey) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--secondary-dark), 0 5px 15px !important;
  height: 35px !important;
  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}

.margin-24 {
  margin: 0px 24px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    // color: #ee5e5e !important;
    color: var(--pri-btn-color) !important;
  }
}

.no-bottom {
  margin-bottom: 15px !important;
}

.tag-wrap {
  display: flex;
  gap: 8px;
  .MuiChip-root {
    background-color: #ff8e97;
    text-transform: capitalize;
    color: var(--primary-text-color) !important;
    border-radius: 4px;
    padding: 4px 0px;
    white-space: nowrap;
    font-weight: 500;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .MuiChip-label {
    color: var(--primary-text-color) !important;
    font-weight: 600;
  }
}

.grey-btn {
  background-color: var(--textgrey) !important;
  color: var(--white) !important;
  transition: box-shadow 0.2s ease-in-out;
  margin-top: 0.2s ease-in-out;
  opacity: 0.2s ease-in-out !important;
  box-shadow: 0 6px 0 var(--secondary-dark), 0 5px 15px !important;
  height: 35px !important;
  &:hover {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:focus {
    opacity: 0.9 !important;
    outline: 0 !important;
  }
  &:active {
    box-shadow: none !important;
    margin-top: 6px !important;
  }
}
