.question-modal-wrapper {
  padding: 28px;
  max-width: 900px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  border-radius: 5px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  .title {
    // h1 {
    //   font-size: 26px !important;
    // }
    // h2 {
    //   font-size: 22px !important;
    // }
    // h3 {
    //   font-size: 18px !important;
    // }
    // h4 {
    //   font-size: 16px !important;
    // }
    p {
      //   color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .footer {
    margin-top: 48px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

.attempt-exam {
  // h1 {
  //   font-size: 26px !important;
  // }
  // h2 {
  //   font-size: 22px !important;
  // }
  // h3 {
  //   font-size: 18px !important;
  // }
  // h4 {
  //   font-size: 16px !important;
  // }
}

// .ql-toolbar.ql-snow {
//   border-top-right-radius: 4px;
//   border-top-left-radius: 4px;
// }

// .ql-container.ql-snow {
//   border-bottom-left-radius: 4px;
//   border-bottom-right-radius: 4px;
//   min-height: 250px;
// }

// .ql-snow.ql-toolbar button:hover {
//   background: var(--primary-text-color);
//   border-radius: 5px;
//   color: var(--white);
// }

// .ql-snow.ql-toolbar button.ql-active {
//   background: var(--primary-text-color);
//   border-radius: 5px;
//   color: var(--white) !important;
// }

// .ql-snow.ql-toolbar button svg {
//   color: var(--white);
// }
// .ql-editor {
//   font-size: initial !important;
// }

// .ql-indent-1 span {
//   font-size: initial !important;
// }

.short-answer {
  .primary-btn:disabled {
    cursor: not-allowed !important;
    opacity: 0.8 !important;
    // box-shadow: none !important;
    transition: none !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    pointer-events: none !important;
  }
}

.green-score {
  color: var(--pri-btn-color) !important;
}

.red-score {
  color: #ff8e97 !important;
}

.access-denied-found {
  height: 80vh;
  .page-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    img {
      max-width: 100%;
    }
    .page-404 {
      max-width: 35%;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 24px !important;
      margin-bottom: 8px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center !important;
    }
    p {
      font-size: 20px;
      margin-bottom: 24px;
      color: var(--primary-btn-hover) !important;
    }
  }
}
